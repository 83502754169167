"use strict";

exports.__esModule = true;
exports.default = void 0;

var _tools = require("@23point5/core/controls/utils/tools");

var _actions = require("@App/actions");

var _moment = _interopRequireDefault(require("moment"));

var _actions2 = require("@pages/DesignEditor/actions");

var _tools2 = require("@common/utils/tools");

var _store = _interopRequireDefault(require("store2"));

var _context = require("@pages/DesignEditor/context");

var _contexts = require("@pages/Session/contexts");

var _pick = _interopRequireDefault(require("lodash/pick"));

var _omit = _interopRequireDefault(require("lodash/omit"));

var _queryString = _interopRequireDefault(require("query-string"));

var _react = require("react");

var _solidColorsData = _interopRequireDefault(require("./solidColorsData"));

var _v = _interopRequireDefault(require("uuid/v4"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

const autoGeneratedProductName = (args = {}) => {
  const isCustomize = args.isCustomize;
  return !isCustomize ? `Design ${(0, _moment.default)().format('MM-DD-YYYY-h-mm-ss')}` : `Customized ${(0, _moment.default)().format('MM-DD-YYYY-h-mm-ss')}`;
};

const tabId = () => {
  const tabID = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = (0, _v.default)();
  return tabID;
};

var _default = props => {
  const _props$design_editor = props.design_editor,
        design_editor = _props$design_editor === void 0 ? {} : _props$design_editor,
        session = props.session,
        dispatch = props.dispatch,
        _props$navProps = props.navProps,
        navProps = _props$navProps === void 0 ? {} : _props$navProps,
        history = props.history,
        location = props.location,
        _props$match = props.match,
        match = _props$match === void 0 ? {} : _props$match,
        dialog = props.dialog,
        sectionBodyRef = props.sectionBodyRef,
        _props$sessionCart = props.sessionCart,
        sessionCart = _props$sessionCart === void 0 ? {} : _props$sessionCart,
        shopifyClient = props.shopifyClient,
        _props$editor = props.editor,
        editor = _props$editor === void 0 ? {} : _props$editor;
  const _location$state = location.state,
        locationState = _location$state === void 0 ? {} : _location$state;
  const styleLocationState = locationState.style;
  const navPromptActive = navProps.isActive;

  const _ref = match.params || {},
        _ref$id = _ref.id,
        params_product_id = _ref$id === void 0 ? '' : _ref$id,
        _ref$user_handle = _ref.user_handle,
        params_user_handle = _ref$user_handle === void 0 ? '' : _ref$user_handle,
        params_product_name = _ref.product_name,
        store_session_id = _ref.store_session_id;

  const _design_editor$is_sty = design_editor.is_styles_components_loading,
        is_styles_components_loading = _design_editor$is_sty === void 0 ? false : _design_editor$is_sty,
        color_schemes = design_editor.color_schemes,
        element_category_group = design_editor.element_category_group,
        _design_editor$saved_ = design_editor.saved_design_data,
        saved_design_data = _design_editor$saved_ === void 0 ? {} : _design_editor$saved_;
  const _editor$is_editor_sav = editor.is_editor_saving_changes,
        is_editor_saving_changes = _editor$is_editor_sav === void 0 ? false : _editor$is_editor_sav;

  const locationSearchParams = _queryString.default.parse((location || {}).search || '');

  const _useState = (0, _react.useState)(window.innerWidth < 1366),
        isMobile = _useState[0];

  const _useState2 = (0, _react.useState)(params_product_id || location.pathname.includes('customize') ? 'loading' : 'style_selection'),
        currentView = _useState2[0],
        setCurrentView = _useState2[1];

  const _useState3 = (0, _react.useState)(null),
        threedData = _useState3[0],
        setThreedData = _useState3[1];

  const _useState4 = (0, _react.useState)(),
        productName = _useState4[0],
        setProductName = _useState4[1];

  const _useState5 = (0, _react.useState)({}),
        productData = _useState5[0],
        setProductData = _useState5[1];

  const _useState6 = (0, _react.useState)(null),
        styleComponents = _useState6[0],
        setStyleComponents = _useState6[1];

  const _useState7 = (0, _react.useState)(''),
        recentElementDialogTab = _useState7[0],
        setRecentElementDialogTab = _useState7[1];

  const _useState8 = (0, _react.useState)([]),
        solidColors = _useState8[0],
        setSolidColors = _useState8[1];

  const _useState9 = (0, _react.useState)([]),
        elementCategoryGroup = _useState9[0],
        setElementCategoryGroup = _useState9[1];

  const _useState10 = (0, _react.useState)(''),
        quickTipEvent = _useState10[0],
        setQuickTipEvent = _useState10[1];

  const _useState11 = (0, _react.useState)(false),
        showRedirectDialog = _useState11[0],
        setShowRedirectDialog = _useState11[1];

  const _useState12 = (0, _react.useState)(''),
        selectedHistoryData = _useState12[0],
        setSelectedHistoryData = _useState12[1];

  const _useState13 = (0, _react.useState)(false),
        openPublishDialog = _useState13[0],
        setOpenPublishDialog = _useState13[1];

  const _useState14 = (0, _react.useState)([]),
        selectedDistributionChannels = _useState14[0],
        setSelectedDistributionChannels = _useState14[1];

  const _useState15 = (0, _react.useState)(),
        latestStyleData = _useState15[0],
        setLatestStyleData = _useState15[1]; // cart data


  const _useState16 = (0, _react.useState)(false),
        isShowAddedToCartDialog = _useState16[0],
        setIsShowAddedToCartDialog = _useState16[1];

  const _useState17 = (0, _react.useState)({}),
        cartDetails = _useState17[0],
        setCartDetails = _useState17[1];

  const _useState18 = (0, _react.useState)(0),
        cartLength = _useState18[0],
        setCartLength = _useState18[1]; // ThreeD data


  const _useState19 = (0, _react.useState)([]),
        backgroundPhotos = _useState19[0],
        setBackgroundPhotos = _useState19[1];

  const _useState20 = (0, _react.useState)([]),
        elementPhotos = _useState20[0],
        setElementPhotos = _useState20[1];

  const _useState21 = (0, _react.useState)(false),
        isStyleUpdateEnabled = _useState21[0],
        setIsStyleUpdateEnabled = _useState21[1];

  const _useState22 = (0, _react.useState)(false),
        isDonePublish = _useState22[0],
        setDonePublish = _useState22[1];

  const _useState23 = (0, _react.useState)(null),
        publishedShopifyResult = _useState23[0],
        setPublishedShopifyResult = _useState23[1]; // const [withLatestStyleData, setWithLatestStyleData] = useState({})


  const _useState24 = (0, _react.useState)([]),
        mockups = _useState24[0],
        setMockups = _useState24[1];

  const _useState25 = (0, _react.useState)(false),
        openUploader = _useState25[0],
        setOpenUploader = _useState25[1];

  const _useState26 = (0, _react.useState)(false),
        openVersionHistory = _useState26[0],
        setOpenVersionHistory = _useState26[1];

  const currentProductVersion = (0, _react.useRef)(0);
  const threeDataRef = (0, _react.useRef)();
  const isActiveStorefrontStore = (0, _react.useMemo)(() => session && session.plans && session.plans['23point5'], [session]);
  const isActiveShopifyStore = (0, _react.useMemo)(() => session && session.plans && session.plans.shopify, [session]);
  const studioUploadApply = (0, _react.useRef)({
    handleImageElementUpload: () => {},
    handleAddElement: () => {},
    handleShowPartsDialogPrompt: null
  });

  const _useState27 = (0, _react.useState)(false),
        isPostMarkCompleteDialogOpen = _useState27[0],
        setisPostMarkCompleteDialogOpen = _useState27[1];

  const generateProfileNavProps = data => {
    const id = data.id,
          first_name = data.first_name,
          last_name = data.last_name,
          name = data.name,
          username = data.username,
          code = data.code,
          _data$store = data.store,
          store = _data$store === void 0 ? {} : _data$store;
    const handle = store.handle;

    if (username || code || first_name || name) {
      const name_handle = first_name ? `${first_name}${last_name ? `-${last_name}` : ''}-${code}` : `${name.trim().replace(/\s/g, '-')}-${code}`;
      const url_params = handle || username || name_handle;
      return {
        label: (0, _tools2.TruncateString)(`Hi, ${(0, _tools2.capitalizeFirstLetter)(first_name)}`, 50),
        // route: `/creator/${url_params}?tab=Featured`
        route: `/creator/${url_params}`
      };
    }

    return '';
  };

  (0, _react.useEffect)(() => {
    if (session.id && currentView === 'editor') {
      dispatch((0, _actions.GenerateProductName)({
        artist_id: session.id,
        postDispatches: [result => {
          if (result && (result || {}).name) {
            setProductName(result.name);
          }
        }]
      }));
    }
  }, []);
  (0, _react.useEffect)(() => {
    dispatch({
      type: 'STORE_NAV_PROPS',
      payload: navProps
    });
    const isActiveAll = [isActiveStorefrontStore, isActiveShopifyStore].every(e => e);

    if (!isActiveAll && isActiveShopifyStore) {
      dispatch((0, _actions2.GetEditorCreatorShopifyStores)({
        callback: (shopif_stores_result = []) => {
          setSelectedDistributionChannels(shopif_stores_result);
        }
      }));
    } else if (!isActiveAll && isActiveStorefrontStore) {
      setSelectedDistributionChannels(['23point5']);
    }
  }, [isActiveStorefrontStore, isActiveShopifyStore]);
  (0, _react.useEffect)(() => {
    dispatch((0, _actions2.GetColorSchemes)());
    dispatch((0, _actions2.GetCategoryGroups)());
  }, []);
  (0, _react.useEffect)(() => {
    setSolidColors(color_schemes || []);
  }, [color_schemes]);
  (0, _react.useEffect)(() => {
    setElementCategoryGroup(element_category_group || []);
  }, [element_category_group]); // initialize ref for threedData, used when user edits a design

  (0, _react.useEffect)(() => {
    if (!openPublishDialog) return;

    if (threedData || (threedData || {}).id) {
      threeDataRef.current = threedData;
      dispatch((0, _actions2.GetStyleMockups)({
        style_type_name: threedData.style_type_name,
        callback: result => {
          setMockups(result);
        },
        loadingCallback: () => {}
      }));
    }
  }, [threedData, openPublishDialog]);
  (0, _react.useEffect)(() => {
    dispatch((0, _actions2.CreateProductSetViewMode)(currentView));
  }, [currentView]); // IF CUSTOMIZE

  (0, _react.useEffect)(() => {
    if (location.pathname.includes('customize') && params_product_id || location.pathname.includes('customize') && params_user_handle && params_product_name) {
      setBackgroundPhotos([]);
      setElementPhotos([]);

      const creatorRequestParam = () => {
        const splitted_user_handle = params_user_handle.split('-').reverse();
        const decoded_product_name = params_product_name.trim().replace(/_/g, ' ');

        if (splitted_user_handle.length === 1) {
          const username = splitted_user_handle[0];
          return {
            username,
            product_name: decoded_product_name
          };
        }

        const creator_code = splitted_user_handle[0];
        return {
          code: creator_code,
          product_name: decoded_product_name
        };
      }; // localStorage.remove('threedData')


      setCurrentView('loading');
      dispatch((0, _actions2.CreateGetProductByParams)(_objectSpread(_objectSpread({}, creatorRequestParam()), {}, {
        callback: resultData => {
          const _resultData$error = resultData.error,
                error = _resultData$error === void 0 ? false : _resultData$error,
                _resultData$product_d = resultData.product_details,
                result_product_details = _resultData$product_d === void 0 ? {} : _resultData$product_d,
                isStyleUpdateEnabled = resultData.isStyleUpdateEnabled,
                _resultData$with_late = resultData.with_latest_style_data,
                with_latest_style_data = _resultData$with_late === void 0 ? {} : _resultData$with_late;

          if (error) {
            history.push('/not-found');
            return;
          }

          if (result_product_details.status === 'Approved' && !result_product_details.is_customizable) {
            history.push('/shop-product');
            return;
          }

          const productDataFieldKeys = ['product_category_id', 'description', 'default_markup', 'design_tags', 'availability', 'stock', 'base_price', 'pricing', 'price', 'collections', 'style_type_name', 'make_price', 'demographic', 'include_size_guide', 'demographic_sizes'];
          const omittedFields = [!result_product_details.is_customize && 'id', 'is_template', 'code', 'products', 'username', 'last_name', 'first_name', 'saved', 'shopify_product_id', 'shopify_product_variant_id'].filter(Boolean);
          setThreedData(_objectSpread(_objectSpread({}, (0, _omit.default)(result_product_details, omittedFields)), {}, {
            parent_product_id: result_product_details.id,
            parent_artist_id: result_product_details.artist_id,
            is_customize: true,
            is_draft: true
          }));
          setProductData((0, _pick.default)(result_product_details, productDataFieldKeys));

          if (!result_product_details.is_customize) {
            if (session.id) {
              dispatch((0, _actions.GenerateProductName)({
                artist_id: session.id,
                postDispatches: [result => {
                  if (result && (result || {}).name) {
                    setProductName(result.name);
                  }
                }]
              }));
            }
          } else {
            setProductName(result_product_details.product_name);
          }

          setCurrentView(isMobile ? 'mobileEditor' : 'editor');
          setIsStyleUpdateEnabled(isStyleUpdateEnabled); // setWithLatestStyleData(with_latest_style_data)
          // if (isStyleUpdateEnabled) {
          //   dispatch(
          //     ShowDialog({
          //       uniqueEntity: 'new_style_notification',
          //       data: {
          //         threedData: with_latest_style_data,
          //         handleUpdateProductStyleVersion: () => {
          //           // IF CUSTOMIZE: just set the data with latest style on the state
          //           setThreedData({
          //             ...omit(with_latest_style_data, omittedFields),
          //             parent_product_id: with_latest_style_data.id,
          //             parent_artist_id: with_latest_style_data.artist_id,
          //             is_customize: true
          //           })
          //           setProductData(pick(with_latest_style_data, productDataFieldKeys))
          //           setIsStyleUpdateEnabled(false)
          //           setWithLatestStyleData({})
          //         }
          //       },
          //       context: dialogContext.latestStyleNotification
          //     })
          //   )
          // }
        }
      })));
    }
  }, []); // IF EDIT EXISTING NEW DESIGN

  (0, _react.useEffect)(() => {
    handleFetchProductDetails();
  }, []);
  (0, _react.useEffect)(() => {
    // GUARD: If "params_product_id" was passed from the URl params
    if (params_product_id) {
      return;
    } // CHECK CURRENT VIEW IF STYLE SELECTION


    if (!styleLocationState && !Object.keys(styleLocationState || {}).length && !location.pathname.includes('customize')) {
      setCurrentView('style_selection');
      return;
    }

    if (!styleLocationState && currentView === 'editor') {
      window.location.reload();
    }

    if (typeof styleLocationState === 'string' && (styleLocationState || '').split('-')[0] === 'STYLE_LIST') {
      // handleFetchStylesList()
      return;
    } // GUARD: If a selected style is passed in the URL params


    if (styleLocationState && Object.keys(styleLocationState || {}).length) {
      // localStorage.remove('threedData')
      setCurrentView('loading');
      handleStyleSelect(styleLocationState);

      if (session.id) {
        // setProductName(autoGeneratedProductName())
        dispatch((0, _actions.GenerateProductName)({
          artist_id: session.id,
          postDispatches: [result => {
            if (result && (result || {}).name) {
              setProductName(result.name);
            }
          }]
        }));
      }

      dispatch((0, _actions2.ClearEditorSavedDesign)());
      return;
    } // GUARD: If no selected style & product id passed, fetch styles


    if (!styleLocationState && !params_product_id && !params_user_handle && !params_product_name) {// localStorage.remove('threedData')
      // handleFetchStylesList()
    }

    if (session.id && !params_user_handle && !params_product_name) {
      // setProductName(autoGeneratedProductName())
      dispatch((0, _actions.GenerateProductName)({
        artist_id: session.id,
        postDispatches: [result => {
          if (result && (result || {}).name) {
            setProductName(result.name);
          }
        }]
      }));
      dispatch({
        type: 'GET_PRODUCT_HISTORY',
        payload: {
          data: []
        }
      });
    }
  }, [styleLocationState]);
  (0, _react.useEffect)(() => {
    if (navPromptActive) {
      const _navProps$onCancel = navProps.onCancel,
            onCancel = _navProps$onCancel === void 0 ? () => {} : _navProps$onCancel;

      if (dialog.isVisible) {
        dispatch((0, _actions.HideDialog)());
        setTimeout(() => {
          dispatch((0, _actions.ShowDialog)({
            uniqueEntity: 'nav-prompt-destination-new',
            data: navProps,
            onCloseCallback: () => {
              onCancel();
            },
            onProceedCallback: ({
              callback = () => {}
            }) => {
              dispatch({
                type: 'PROCESSING_APP'
              }); // if (threedData.is_draft) {
              //   dispatch(
              //     EditorDeleteDesign({
              //       id: threedData.id,
              //       artist_id: threedData.artist_id,
              //       callback
              //     })
              //   )
              // } else {
              //   callback()
              // }

              dispatch((0, _actions.DialogUpdating)());
              const saveButton = document.getElementById('instant-save-btn');

              if (saveButton) {
                saveButton.click();
              }
            },
            context: _context.dialogContext.confirmNavigateDialog
          }));
        }, 300);
        return;
      }

      dispatch((0, _actions.ShowDialog)({
        uniqueEntity: 'nav-prompt-destination-new',
        data: navProps,
        onCloseCallback: () => {
          onCancel();
        },
        onProceedCallback: ({
          callback = () => {}
        }) => {
          // if (threedData.is_draft) {
          //   dispatch(
          //     EditorDeleteDesign({
          //       id: threedData.id,
          //       artist_id: threedData.artist_id,
          //       callback
          //     })
          //   )
          // } else {
          //   callback()
          // }
          dispatch((0, _actions.DialogUpdating)());
          const saveButton = document.getElementById('instant-save-btn');

          if (saveButton) {
            saveButton.click();
          }
        },
        context: _context.dialogContext.confirmNavigateDialog
      }));
    }
  }, [navPromptActive]);
  (0, _react.useEffect)(() => {
    if (is_styles_components_loading) {
      setCurrentView('loading');
    }
  }, [is_styles_components_loading]); // START --> PAGE HANDLERS  ---------------------------------------------------------------------------------

  const handleFetchProductDetails = () => {
    if (params_product_id) {
      setBackgroundPhotos([]);
      setElementPhotos([]);
      setCurrentView('loading');
      dispatch((0, _actions2.CreateGetProductDetails)({
        id: params_product_id,
        notFoundCallback: () => {
          history.push('/not-found');
        },
        postDispatches: [params => {
          const _params$product_detai = params.product_details,
                result_product_details = _params$product_detai === void 0 ? {} : _params$product_detai,
                isStyleUpdateEnabled = params.isStyleUpdateEnabled,
                _params$with_latest_s = params.with_latest_style_data,
                with_latest_style_data = _params$with_latest_s === void 0 ? {} : _params$with_latest_s,
                _params$latest_style = params.latest_style,
                latest_style = _params$latest_style === void 0 ? {} : _params$latest_style; // GUARDS:
          // 1. If no signed in user
          // 2. if current user doesn't own the product
          // 3. if status is not equal to new

          if (!session.id || result_product_details.artist_id !== session.id) {
            history.push('/not-found');
            return;
          }

          setLatestStyleData((0, _pick.default)(with_latest_style_data, ['id', 'demographic', 'name', 'display_name']));
          const productDataFieldKeys = ['product_category_id', 'description', 'default_markup', 'design_tags', 'availability', 'stock', 'base_price', 'pricing', 'price', 'collections', 'style_type_name', 'make_price', 'demographic', 'include_size_guide', 'kids_base_price', 'kids_default_markup', 'kids_make_price'];
          const shaped_result_data = result_product_details.is_locked ? (0, _omit.default)(result_product_details, 'id') : result_product_details;
          const initial_kids_retail = Number(latest_style.kids_price || latest_style.price) + Number(latest_style.kids_default_markup || latest_style.default_markup);
          const _session$royalty = session.royalty,
                royalty = _session$royalty === void 0 ? {} : _session$royalty;
          const initial_kids_markup = Number(initial_kids_retail) * Number((royalty.percentage || 10) / 100);
          setThreedData(shaped_result_data);
          setProductData(_objectSpread(_objectSpread({}, (0, _pick.default)(shaped_result_data, productDataFieldKeys)), {}, {
            pricing: _objectSpread(_objectSpread({}, shaped_result_data.pricing), {}, {
              kids_markup: initial_kids_markup,
              kids_retail: initial_kids_retail
            }),
            demographic_sizes: {
              adults: true,
              kids: result_product_details.demographic.includes('kids')
            }
          }));
          setCurrentView(isMobile ? 'mobileEditor' : 'editor');
          setProductName(shaped_result_data.product_name);
          setIsStyleUpdateEnabled(isStyleUpdateEnabled);
          handleFetchHistory(result_product_details); // setWithLatestStyleData(with_latest_style_data)
          // if (isStyleUpdateEnabled) {
          //   dispatch(
          //     ShowDialog({
          //       uniqueEntity: 'new_style_notification',
          //       data: {
          //         threedData: with_latest_style_data,
          //         handleUpdateProductStyleVersion: () => handleUpdateProductStyleVersion(with_latest_style_data)
          //       },
          //       context: dialogContext.latestStyleNotification
          //     })
          //   )
          // }
        }]
      }));
    }
  };

  const handleUpdateProductStyleVersion = (withLatestStyleData = {}) => {
    dispatch({
      type: 'IS_EDITOR_SAVING_CHANGES'
    });
    const postDispatches = [(resultProductData = {}) => {
      // setThreedData(withLatestStyleData)
      handleFetchProductDetails(); // setIsStyleUpdateEnabled(false)
      // setWithLatestStyleData({})
    }];
    const payload = {
      session_data: session,
      threed_data: withLatestStyleData,
      product_data: _objectSpread(_objectSpread({}, productData), {}, {
        id: withLatestStyleData.id,
        product_name: withLatestStyleData.product_name
      }),
      postDispatches,
      method: 'PUT',
      isAutosave: false,
      customSuccessMessage: 'Product Style Successfully Updated.'
    };
    dispatch((0, _actions2.EditorSaveDesignChanges)(payload));
  };

  const handleFetchStylesList = params => {
    dispatch((0, _actions2.CreateGetCreateStylesList)(_objectSpread(_objectSpread({}, params), {}, {
      callback: () => {
        setCurrentView('style_selection');
      }
    })));
  };

  const handleViewChange = next_view => {
    setCurrentView(next_view);
  };

  const handleStyleSelect = (selectedStyle = {}) => {
    debouncer(() => {
      if (Object.keys(selectedStyle || {}).length) {
        setCurrentView('loading');
        dispatch({
          type: 'GET_EDITOR_STYLE_COMPONENTS_LOADING'
        }); // Remove persisted threedData from localstorage
        // localStorage.remove('threedData')

        setBackgroundPhotos([]);
        setElementPhotos([]);
        dispatch((0, _actions2.CreateGetDesignEditorStyleComponents)(_objectSpread(_objectSpread({}, selectedStyle), {}, {
          callback: (result_threed_model_data, result_style_components) => {
            if (Object.keys(result_threed_model_data || {}).length) {
              const initial_retail = Number(result_threed_model_data.base_price) + Number(result_threed_model_data.default_markup);
              const royalty = session.royalty;
              const initial_markup = Number(initial_retail) * Number(((royalty === null || royalty === void 0 ? void 0 : royalty.percentage) || 10) / 100); // 10 here is a fallback

              let kidsPricingData = {};
              const hasKidsSizes = (result_threed_model_data.demographic || []).some(e => e === 'kids');
              const kids_base_price = result_threed_model_data.kids_base_price || result_threed_model_data.base_price;
              const kids_default_markup = result_threed_model_data.kids_default_markup || result_threed_model_data.default_markup;

              if (hasKidsSizes) {
                const initial_kids_retail = Number(kids_base_price) + Number(kids_default_markup);
                const initial_kids_markup = Number(initial_kids_retail) * Number(((royalty === null || royalty === void 0 ? void 0 : royalty.percentage) || 10) / 100);
                kidsPricingData = {
                  kids_markup: initial_kids_markup,
                  kids_retail: initial_kids_retail
                };
              }

              setThreedData(_objectSpread(_objectSpread({}, result_threed_model_data), {}, {
                demographic_sizes: {
                  adults: result_threed_model_data.demographic.includes('adults'),
                  kids: result_threed_model_data.demographic.includes('kids')
                }
              }));
              setStyleComponents(result_style_components);
              setCurrentView(isMobile ? 'mobileEditor' : 'editor');
              setProductData({
                collections: [],
                product_category_id: result_threed_model_data.product_category_id || '',
                description: result_threed_model_data.description || '',
                default_markup: result_threed_model_data.default_markup || '0.00',
                design_tags: result_threed_model_data.design_tags || [],
                availability: result_threed_model_data.availability || 'For Everyone',
                stock: result_threed_model_data.stock || 1,
                base_price: result_threed_model_data.base_price || 0,
                make_price: result_threed_model_data.make_price || 0,
                kids_make_price: result_threed_model_data.kids_make_price || 0,
                kids_base_price,
                kids_default_markup,
                pricing: _objectSpread({
                  markup: initial_markup,
                  retail: initial_retail
                }, kidsPricingData),
                price: 0.0,
                demographic_sizes: {
                  adults: true,
                  kids: result_threed_model_data.demographic.includes('kids')
                }
              });
            }
          }
        })));
      }
    }, 100);
  };

  const handleSaveDetailsChanges = (data = {}) => {
    const payload = {
      data: _objectSpread(_objectSpread({}, data), {}, {
        id: threedData.id
      }),
      postDispatches: [resultProductData => {
        threeDataRef.current = resultProductData;
        setThreedData(resultProductData);
        setProductName(resultProductData.product_name);
      }]
    };
    dispatch((0, _actions2.EditorUpdateProductDetails)(payload));
  }; // END <-- PAGE HANDLERS  ---------------------------------------------------------------------------------
  // START <-- AUTOSAVE HANDLERS  ---------------------------------------------------------------------------------
  // save without using the toast and dialog


  const handleHeadlessSave = args => {
    if (!session.id) return;
    if (!productName) return;
    if (openPublishDialog) return; // if(params_user_handle && params_product_name && location.pathname.includes('customize') && !threedData.id) return

    const _args$threed_data = args.threed_data,
          threed_data = _args$threed_data === void 0 ? {} : _args$threed_data,
          _args$progressHandler = args.progressHandlerCallback,
          progressHandlerCallback = _args$progressHandler === void 0 ? () => {} : _args$progressHandler;
    const saved = threed_data.saved; // PUT if update, POST if create

    const method = saved ? 'PUT' : 'POST';
    dispatch({
      type: 'IS_EDITOR_SAVING_CHANGES'
    });
    const postDispatches = [(resultProductData = {}) => {
      // update ref after successfully saving the data
      threeDataRef.current = resultProductData;
      setThreedData(resultProductData);
      setProductData(data => _objectSpread(_objectSpread({}, data), {}, {
        collections: resultProductData.collections
      }));
      setProductName(resultProductData.product_name);
    }];
    const payload = {
      session_data: session,
      threed_data: _objectSpread(_objectSpread({}, threed_data), {}, {
        id: (threeDataRef.current || {}).id,
        editor_id: tabId()
      }),
      product_data: _objectSpread(_objectSpread({}, productData), {}, {
        product_name: productName // is_draft: !!threed_data.is_draft || !threed_data.id

      }),
      postDispatches,
      method,
      progressHandlerCallback,
      isAutosave: true,
      redirectCallback: resultProductData => {
        const _ref2 = location || {},
              pathname = _ref2.pathname;

        const _split = (pathname || '').split('/'),
              exact_path = _split[1];

        if (location.pathname.includes('customize')) {
          const code = session.code,
                username = session.username,
                first_name = session.first_name,
                last_name = session.last_name;
          const style_type_name = resultProductData.style_type_name;
          const creator_handle = username || `${`${first_name} ${last_name}`.trim().replace(/\s/g, '-')}-${code}`;
          const encoded_product_name = resultProductData.product_name.trim().replace(/\s/g, '_');
          const targetPath = `/customize/${style_type_name}/${creator_handle}/${encoded_product_name}?code=${code}`;
          history.replace(targetPath);
        } else {
          const hasParamsId = exact_path !== 'create' || exact_path === 'create' && !!params_product_id;

          if (!hasParamsId) {
            updateAccessTime(resultProductData.id);
            history.push(`/create/${resultProductData.id}${Object.keys(locationSearchParams).length ? `?${_queryString.default.stringify(locationSearchParams)}` : ''}`);
          }
        }
      },
      setShowRedirectDialog
    };
    dispatch((0, _actions2.EditorSaveDesignChanges)(payload));
  }; // END <-- AUTOSAVE HANDLERS  ---------------------------------------------------------------------------------
  // START --> THREED HANDLERS  ---------------------------------------------------------------------------------


  const handleThreeDChange = (unapplied, unsaved) => {
    dispatch((0, _actions2.CreateProductModifiedFlag)(unsaved));
  };

  const handleUpdateEditorBackgroundPhotos = (new_background_photos = []) => {
    setBackgroundPhotos((prev = []) => [...new_background_photos, ...prev]);
  };

  const handleRemoveEditorBackgroundPhotos = key => {
    setBackgroundPhotos((prev = []) => prev.filter(e => e.key !== key));
  };

  const handleUpdateEditorElemementPhotos = (new_element_photos = []) => {
    setElementPhotos((prev = []) => [...new_element_photos, ...prev]);
  };

  const handleRemoveUploadedBranding = () => {
    setThreedData((prev = {}) => _objectSpread(_objectSpread({}, prev), {}, {
      branding: {}
    }));
  };

  const handleWebflowSignup = ({
    data,
    progressHandlerCallback = () => {}
  }) => {
    const _data$firstName = data.firstName,
          firstName = _data$firstName === void 0 ? '' : _data$firstName,
          _data$lastName = data.lastName,
          lastName = _data$lastName === void 0 ? '' : _data$lastName,
          _data$email = data.email,
          email = _data$email === void 0 ? '' : _data$email; // dispatch(
    //   ShowDialog({
    //     uniqueEntity: 'design_editor',
    //     // onCloseCallback: () => {
    //     //   dispatch({ type: 'IS_EDITOR_SAVING_CHANGES_DONE' })
    //     // },
    //     data: {
    //       isShowSuccessToast: false,
    //       isSignupAllowed: true,
    //       view: 'sign-up',
    //       webflowSignup: true,
    //       firstName,
    //       lastName,
    //       email,
    //       callback: post_auth_session => {
    //         // dispatch({
    //         //   type: 'UPDATE_SESSION_DATA',
    //         //   payload: post_auth_session
    //         // })
    //         // const product_name = autoGeneratedProductName()
    //         // // TRIGGER SAVE CHANGES HANDLER
    //         // setProductName(product_name)
    //         // handleSaveChanges({
    //         //   session_data: post_auth_session,
    //         //   threed_data,
    //         //   product_data: {
    //         //     ...productData,
    //         //     product_name,
    //         //     ...(threedData.is_customized ? { product_name: productName } : {})
    //         //   },
    //         //   progressHandlerCallback
    //         // })
    //         history.push('/create')
    //       }
    //     },
    //     context: sessionDialogContext.signinSignupUser
    //   })
    // )
  };

  const handlePreSaveFunction = props => {
    var _threed_data;

    const _props$progressHandle = props.progressHandlerCallback,
          progressHandlerCallback = _props$progressHandle === void 0 ? () => {} : _props$progressHandle,
          _props$isSaveAsCopy = props.isSaveAsCopy,
          isSaveAsCopy = _props$isSaveAsCopy === void 0 ? false : _props$isSaveAsCopy,
          _props$webflowUser = props.webflowUser,
          webflowUser = _props$webflowUser === void 0 ? {} : _props$webflowUser,
          _props$isAutosave = props.isAutosave,
          isAutosave = _props$isAutosave === void 0 ? false : _props$isAutosave;
    let threed_data = props.threed_data; // CHECK IF USER IS LOGGED IN
    // PROMPT SIGNIN/SIGNUP DIALOG IF USER IS NOT LOGGED IN

    if (!threed_data.thumbnail_path && !threed_data.file_path) {
      threed_data = (0, _omit.default)(threed_data, ['thumbnail_path', 'file_path', 'snapshots']);
    }

    if (is_editor_saving_changes) return;
    dispatch({
      type: 'IS_EDITOR_SAVING_CHANGES'
    });

    if (!session.id) {
      // PROMPT SIGNIN/SIGNUP DIALOG IF NO LOGGED IN USER
      dispatch((0, _actions.ShowDialog)({
        uniqueEntity: 'design_editor',
        onCloseCallback: () => {
          dispatch({
            type: 'IS_EDITOR_SAVING_CHANGES_DONE'
          });
        },
        data: _objectSpread(_objectSpread({}, webflowUser), {}, {
          isShowSuccessToast: false,
          isSignupAllowed: true,
          view: 'sign-up',
          callback: (post_auth_session, generatedProductName) => {
            dispatch({
              type: 'UPDATE_SESSION_DATA',
              payload: post_auth_session
            }); // const product_name = autoGeneratedProductName()

            const product_name = generatedProductName; // TRIGGER SAVE CHANGES HANDLER

            setProductName(product_name);
            handleSaveChanges({
              session_data: post_auth_session,
              threed_data,
              product_data: _objectSpread(_objectSpread({}, productData), {}, {
                product_name
              }, threedData.is_customized ? {
                product_name: productName
              } : {}),
              progressHandlerCallback,
              isAutosave
            });
          }
        }),
        context: _contexts.dialogContext.signinSignupUser
      }));
      return;
    } // IF USER IS ALREADY LOGGED IN
    // TRIGGER SAVE CHANGES HANDLER


    if ((_threed_data = threed_data) !== null && _threed_data !== void 0 && _threed_data.id) {
      setThreedData(threed_data);
      setProductData(data => _objectSpread(_objectSpread({}, data), {}, {
        collections: threed_data.collections
      }));
      setProductName(threed_data.product_name);
      handleFetchHistory(threed_data);
    } else {
      handleSaveChanges({
        session_data: session,
        threed_data,
        product_data: _objectSpread(_objectSpread({}, productData), {}, {
          product_name: productName
        }),
        progressHandlerCallback,
        isSaveAsCopy,
        isAutosave
      });
    }
  };

  const handleGetReadOnlyData = () => {
    dispatch((0, _actions2.CreateGetReadOnlyData)());
  };

  const handleReviseDesign = () => {
    const postDispatches = [(resultProductData = {}) => {
      setThreedData(resultProductData);
    }];
    dispatch((0, _actions2.CreateReviseDesign)({
      threed_data: threedData,
      product_data: _objectSpread(_objectSpread({}, productData), {}, {
        is_revised: true,
        status: 'New',
        visibility: 'Private'
      }),
      postDispatches
    }));
  };

  const handleApplyTemplate = (template = {}) => {
    setCurrentView('loading');
    dispatch((0, _actions2.CreateGetProductDetails)({
      id: template.id,
      postDispatches: [(_ref3) => {
        let _ref3$product_details = _ref3.product_details,
            result_product_details = _ref3$product_details === void 0 ? {} : _ref3$product_details,
            restResultData = _objectWithoutPropertiesLoose(_ref3, ["product_details"]);

        const omittedFields = ['product_category_id', 'description', 'default_markup', 'design_tags', 'availability', 'stock', 'base_price', 'pricing', 'price', 'collections', 'status', 'id', 'visibility'];

        const shapeNewThreedData = _objectSpread(_objectSpread({}, (0, _omit.default)(result_product_details, omittedFields)), {}, {
          status: 'New',
          visibility: 'Private',
          is_locked: false,
          branding: {}
        });

        setThreedData(shapeNewThreedData);
        setCurrentView(isMobile ? 'mobileEditor' : 'editor');
      }]
    }));
  };

  const handleViewTutorial = () => {
    _store.default.set('disable_editor_tour', false);
  }; // END <-- THREED HANDLERS  -----------------------------------------------------------------------------------
  // START --> DIALOG HANDLERS ---------------------------------------------------------------------------------------


  const handlePrompUpdateStyleVersion = () => {// if (isStyleUpdateEnabled) {
    //   dispatch(
    //     ShowDialog({
    //       uniqueEntity: 'new_style_notification',
    //       data: {
    //         threedData: withLatestStyleData,
    //         handleUpdateProductStyleVersion: () => {
    //           if (params_user_handle && params_product_name && location.pathname.includes('customize')) {
    //             // IF CUSTOMIZE: just set the data with latest style on the state
    //             setThreedData({
    //               ...threedData,
    //               prev_style_id: threedData.style_id,
    //               style_id: withLatestStyleData.style_id,
    //               wrap_modes: withLatestStyleData.wrap_modes,
    //               preview_screenshot_position: threedData.preview_screenshot_position,
    //               style_name: threedData.name,
    //               shipment_details: threedData.shipment_details
    //             })
    //             setIsStyleUpdateEnabled(false)
    //             setWithLatestStyleData({})
    //             return
    //           }
    //           handleUpdateProductStyleVersion(withLatestStyleData)
    //         }
    //       },
    //       context: dialogContext.latestStyleNotification
    //     })
    //   )
    // }
  };

  const handleShowBackgroundDialog = () => {
    dispatch((0, _actions.ShowDialog)({
      uniqueEntity: 'design_editor',
      data: {
        callbacks: {
          handleUpdateEditorBackgroundPhotos
        }
      },
      context: _context.dialogContext.backgroundDialog
    }));
  };

  const handleShowElementDialog = (params = {}) => {
    dispatch((0, _actions.ShowDialog)({
      uniqueEntity: 'design_editor_element_dialog',
      sectionBodyRef,
      data: _objectSpread(_objectSpread({}, params), {}, {
        showQuickTip,
        quickTipEvent,
        recentElementDialogTab,
        elementCategoryGroup,
        callbacks: _objectSpread(_objectSpread({}, params.callbacks), {}, {
          handleUpdateEditorElemementPhotos,
          handleApplyTemplate,
          handleSetRecentElementDialogTab
        })
      }),
      context: _context.dialogContext.elementDialog
    }));
  };

  const handleShowPublishDialog = ({
    threed_data
  }) => {
    dispatch({
      type: 'IS_EDITOR_SAVING_CHANGES'
    });

    if (!session.is_verified) {
      dispatch((0, _actions.ShowDialog)({
        uniqueEntity: 'post-auth-notification',
        onCloseCallback: () => {
          dispatch({
            type: 'IS_EDITOR_SAVING_CHANGES_DONE'
          });
        },
        data: {},
        context: _context.dialogContext.emailVerificationRequiredDialog
      }));
      return;
    }

    dispatch((0, _actions.ShowDialog)({
      uniqueEntity: 'design_editor_publish',
      onCloseCallback: () => {
        dispatch({
          type: 'IS_EDITOR_SAVING_CHANGES_DONE'
        });
      },
      data: {
        product_data: _objectSpread(_objectSpread(_objectSpread({}, threed_data), productData), {}, {
          product_name: productName
        }),
        callbacks: {
          handleUpdateThreedData: () => {}
        },
        locationSearchParams
      },
      context: _context.dialogContext.publishDialog
    }));
  };

  const handleUnpublishDesignDialog = () => {
    const postDispatches = [() => {
      window.location.href = `${window.config.admin_experience_app_url}/admin/product`; // history.push('/artist-design')
      // if (shopifyClient) {
      //   dispatch(
      //     GetArtistCart({
      //       shopifyClient
      //     })
      //   )
      // }
    }];
    dispatch((0, _actions.ShowDialog)({
      uniqueEntity: 'design_editor',
      data: {
        threed_data: threedData,
        product_data: productData,
        postDispatches
      },
      context: _context.dialogContext.confirmUnpublishDialog
    }));
  };

  const handleShowPublishConfirmationDialog = () => {
    const postDispatches = [() => {
      // history.push('/artist-design')
      window.location.href = `${window.config.admin_experience_app_url}/admin/product`;
    }];
    dispatch((0, _actions.ShowDialog)({
      uniqueEntity: 'design_editor',
      data: {
        threed_data: threedData,
        product_data: productData,
        postDispatches
      },
      context: _context.dialogContext.confirmPublishDialog
    }));
  };

  const handleCancelApprovalSubmissionDialog = () => {
    const postDispatches = [() => {
      history.push('/artist-design');
    }];
    dispatch((0, _actions.ShowDialog)({
      uniqueEntity: 'design_editor',
      data: {
        threed_data: threedData,
        product_data: productData,
        postDispatches
      },
      context: _context.dialogContext.confirmCancelSubmissionDialog
    }));
  };

  const handleShowSaveDialog = (data = {}) => {
    dispatch((0, _actions.ShowDialog)({
      uniqueEntity: 'design_editor',
      data: _objectSpread(_objectSpread({}, data), {}, {
        showQuickTip
      }),
      context: _context.dialogContext.saveProductDialog,
      onCloseCallback: () => {
        dispatch({
          type: 'IS_EDITOR_SAVING_CHANGES_DONE'
        });
      }
    }));
  };

  const debouncer = (() => {
    let timer;
    return (callback, ms) => {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();

  const handleSaveChanges = (args = {}) => {
    const _args$session_data = args.session_data,
          session_data = _args$session_data === void 0 ? {} : _args$session_data,
          _args$threed_data2 = args.threed_data,
          threed_data = _args$threed_data2 === void 0 ? {} : _args$threed_data2,
          _args$product_data = args.product_data,
          product_data = _args$product_data === void 0 ? {} : _args$product_data,
          _args$progressHandler2 = args.progressHandlerCallback,
          progressHandlerCallback = _args$progressHandler2 === void 0 ? () => {} : _args$progressHandler2,
          _args$isSaveAsCopy = args.isSaveAsCopy,
          isSaveAsCopy = _args$isSaveAsCopy === void 0 ? false : _args$isSaveAsCopy,
          _args$callback = args.callback,
          callback = _args$callback === void 0 ? () => {} : _args$callback,
          _args$isAutosave = args.isAutosave,
          isAutosave = _args$isAutosave === void 0 ? false : _args$isAutosave;
    let threedDataId = threed_data.id;
    const userHandle = params_user_handle;
    let productNameCurrent = params_product_name;
    const threeDataParam = threed_data; // check if threed_data in threedRef (from post dispatch) exists

    if (threeDataRef.current) {
      // if it exists, use ut to determine the method, ID and product name
      threedDataId = threeDataRef.current.id;
      productNameCurrent = threeDataRef.current.productNameCurrent;
    } // original implementation  do not need to check threeDataRef.current


    const method = !!threedDataId ? 'PUT' : 'POST';
    dispatch({
      type: 'IS_EDITOR_SAVING_CHANGES'
    });
    const postDispatches = [(resultProductData = {}) => {
      threeDataRef.current = resultProductData;
      setThreedData(resultProductData);
      setProductData(data => _objectSpread(_objectSpread({}, data), {}, {
        collections: resultProductData.collections
      }));
      setProductName(resultProductData.product_name);
      handleFetchHistory(resultProductData);

      const _ref4 = location || {},
            pathname = _ref4.pathname;

      const _split2 = (pathname || '').split('/'),
            exact_path = _split2[1];

      const hasParamsId = exact_path !== 'create' || exact_path === 'create' && !!params_product_id;

      if (location.pathname.includes('customize')) {
        const code = session.code,
              username = session.username,
              first_name = session.first_name,
              last_name = session.last_name;
        const style_type_name = resultProductData.style_type_name;
        const creator_handle = username || `${`${first_name} ${last_name}`.trim().replace(/\s/g, '-')}-${code}`;
        const encoded_product_name = resultProductData.product_name.trim().replace(/\s/g, '_');
        const targetPath = `/customize/${style_type_name}/${creator_handle}/${encoded_product_name}?code=${code}`;
        history.push(targetPath);
      } else {
        if (!hasParamsId || isSaveAsCopy) {
          updateAccessTime(resultProductData.id);
          history.push(`/create/${resultProductData.id}${Object.keys(locationSearchParams).length ? `?${_queryString.default.stringify(locationSearchParams)}` : ''}`);
        }
      }

      callback();
    }];
    const saving_args = {
      session_data,
      threed_data: _objectSpread(_objectSpread({}, threeDataParam), {}, {
        is_draft: false,
        id: threedDataId,
        editor_id: tabId()
      }),
      product_data: _objectSpread(_objectSpread({}, product_data), {}, {
        is_draft: false // product_name: productNameCurrent || productName

      }),
      method,
      postDispatches,
      progressHandlerCallback,
      onSavingErrorHandler: args => handleShowSaveDialog(args),
      setShowRedirectDialog,
      isSaveAsCopy,
      isAutosave,
      callback,
      postNavConfirmCallback: () => {
        if (dialog.isVisible && dialog.uniqueEntity === 'nav-prompt-destination-new') {
          dispatch((0, _actions.DialogUpdated)());
          navProps.onConfirm();
        }

        if (dialog.isVisible && dialog.uniqueEntity === 'nav-prompt-destination-logout') {
          dispatch((0, _actions.DialogUpdated)());
          navProps.onConfirm();
          dispatch((0, _actions.LogoutUser)()); // close the queue

          socket.end(); // open the socket

          socket.open(); // remove event listener

          socket.removeAllListeners('reconnected');
          socket.isQueue = false;
        }
      }
    }; // handleShowSaveDialog(saving_args)

    dispatch((0, _actions2.EditorSaveDesignChanges)(saving_args));
  };

  const handleSaveAs = saving_args => {
    dispatch((0, _actions2.EditorSaveAsCopy)(saving_args));
  };

  const handleShowAddToCart = () => {
    dispatch((0, _actions.ShowDialog)({
      uniqueEntity: 'design_editor_add_to_cart',
      data: {
        product_data: _objectSpread(_objectSpread(_objectSpread({}, threedData), productData), {}, {
          product_name: productName
        }),
        handleAddToCart
      },
      context: _context.dialogContext.addToCartDialog
    }));
  };

  const handleShowTutorialVideos = () => {
    dispatch((0, _actions.ShowDialog)({
      uniqueEntity: 'editor_tutorial_videos_dialog',
      data: {},
      context: _context.dialogContext.tutorialsDialog
    }));
  };

  const handleShowSafariPrompt = () => {
    dispatch((0, _actions.ShowDialog)({
      uniqueEntity: 'design_editor',
      data: {},
      context: _context.dialogContext.safariWarningDialog
    }));
  }; // END <-- DIALOG HANDLERS ---------------------------------------------------------------------------------------
  // START <-- CART HANDLERS ---------------------------------------------------------------------------------------


  const handleToggleShowAddedToCartDialog = value => {
    setIsShowAddedToCartDialog(value);
  };

  const handleAddToCart = details => {
    // Google Analytics event tracing
    gtag('event', 'add_to_cart', {
      event_category: 'Add to cart',
      event_label: 'product added to cart',
      value: {
        product_id: details.id,
        product_artist_id: details.artist_id,
        artist_id: session.id ? session.id : 'Guest'
      }
    });
    dispatch((0, _actions.ShowSideCartPanel)());
    dispatch((0, _actions.AddPendingProductToCart)({
      shopifyClient: shopifyClient,
      added_cart_item: details,
      isTriggerEditorLoading: true,
      callback: ({
        updated_product
      }) => {
        setThreedData(prev => _objectSpread(_objectSpread({}, prev), updated_product));
      }
    }));
  };

  const handleRemoveToCart = data => {
    dispatch((0, _actions.RemoveFromSessionCart)({
      product: data,
      callback: ({
        cart_items_count
      }) => {
        setCartLength(cart_items_count);
        handleToggleShowAddedToCartDialog(false);
      }
    }));
  };

  const handleUpdateCart = updatedProduct => {
    dispatch((0, _actions.UpdateSessionCart)({
      product: updatedProduct,
      callback: ({
        cart_items_count
      }) => {
        setCartLength(cart_items_count);
      }
    }));
  };

  const handleCartQuantityChange = (action, data) => {
    const pricing = data.pricing,
          quantity = data.quantity,
          stock = data.stock,
          availability = data.availability;

    if (action === 'increment' && availability === 'Limited Edition' && Number(quantity) >= Number(stock)) {
      dispatch((0, _actions.ShowError)({
        message: 'Quantity cannot exceed the available stocks.'
      }));
      return;
    }

    let new_quantity = quantity;

    if (action === 'decrement') {
      new_quantity = quantity - 1;
    }

    if (action === 'increment') {
      new_quantity = quantity + 1;
    }

    const newData = _objectSpread(_objectSpread({}, data), {}, {
      quantity: Number(new_quantity),
      total_price: Number(new_quantity) * Number(pricing.retail)
    });

    setCartDetails(newData);
    handleUpdateCart(newData);
  }; // END <-- CART HANDLERS ---------------------------------------------------------------------------------------
  // START <-- CUSTOM LOGIC HANDLERS ---------------------------------------------------------------------------------------


  const handleSetRecentElementDialogTab = tab => {
    setRecentElementDialogTab(tab);
  };

  const showQuickTip = (event, delay) => {
    setQuickTipEvent(''); // if (delay && delay === parseInt(delay, 10)) setTimeout(() => setQuickTipEvent(event), delay)
    // else setQuickTipEvent(event)
  }; // update operation of access


  const updateAccessTime = productId => {
    const KEY_PREFIX = 'MULT_PROD_';
    const newKey = `${KEY_PREFIX}${productId}`;
    const currentTime = new Date().getTime();
    localStorage.setItem(newKey, `${currentTime},${tabId()}`);
  }; // multiple tab in same browser guard


  const checkMultipleAccess = productId => {
    // allow edit if no id (new)
    if (!productId) return true; // generate tab id

    const KEY_PREFIX = 'MULT_PROD_';
    const newKey = `${KEY_PREFIX}${productId}`;
    const currentTime = new Date().getTime(); // get all local storage keys

    const keys = Object.keys(localStorage);
    let result;
    let found = false; // check existing keys

    for (const key of keys) {
      // identify if product identifier key is a part of this operation
      if (key.startsWith(KEY_PREFIX)) {
        // check if key == to product edit key
        if (newKey === key) {
          found = true; // check updated time of local storage key

          const data = localStorage.getItem(key).split(',');
          const localStorageTime = parseInt(data[0]);
          const localStorageTabId = data[1]; // if local storage time is past 2 minutes, allow access

          if (currentTime - localStorageTime > 120000 || localStorageTabId === tabId()) {
            updateAccessTime(productId);
            result = true;
          } else {
            // do not allow access, product still accessed
            result = false;
          }
        } else {
          // remove local storage record if current time - local storage time > 2 mins
          const localStorageTime = parseInt(localStorage.getItem(key));

          if (currentTime - localStorageTime > 120000) {
            localStorage.removeItem(key);
          }
        }
      }
    } // key do not exist yet


    if (!found) {
      result = true;
      updateAccessTime(productId);
    }

    return result;
  };

  const handleFetchHistory = product_data => {
    if (!product_data) return;
    dispatch((0, _actions2.GetProductHistory)((0, _pick.default)(product_data, ['id'])));
  };

  const handleSetSelectedHistoryData = selected => {
    showQuickTip('ON_VERSION_HISTORY', 1000);
    setSelectedHistoryData(selected);
    dispatch((0, _actions2.ApplyProductHistoryData)({
      key: selected.Key,
      postDispatches: [resultData => {
        setThreedData(resultData);
      }]
    }));
  };

  const handletSetDonePublish = value => {
    setDonePublish(value);
  };

  const handleShowPublishWizardDialog = value => {
    setOpenPublishDialog(value);
  };

  const handleSelectDistributionChannel = value => {
    setSelectedDistributionChannels(value);
  };

  const handleSubmitPublish = data => {
    const productPickedFields = ['product_name', 'description', 'collections', 'file_path', 'mockup_display_side', 'uniqueFootprint', 'demographic_sizes', 'shopify_price', 'kids_shopify_price'];
    const mockupPickedFields = ['mockup_group_id', 'has_3d_preview', 'demographic', 'model_type'];
    const stock_field = data.availability !== 'Limited Edition' ? {
      stock: 0
    } : {
      stock: data.stock
    };
    const is_publish_to_marketplace = selectedDistributionChannels.find(e => e === '23point5');
    const publish_fields = !is_publish_to_marketplace || locationSearchParams.store_session_id ? {
      status: 'Approved',
      visibility: 'Private'
    } : {
      status: 'Approved',
      visibility: 'Public'
    };
    const selectedExternalStores = locationSearchParams.store_session_id ? [{
      artist_session_id: locationSearchParams.store_session_id,
      name: locationSearchParams.store_name
    }] : selectedDistributionChannels.filter(e => e !== '23point5');
    const isTextureGenerated = !!threedData.texture_id && threedData.texture_id === threedData.uniqueFootprint && threedData.texture_status === 'generated' && !!threedData.model_file_path;
    const textureFields = !isTextureGenerated ? {
      texture_status: 'pending',
      texture_id: '',
      mockups: []
    } : {};

    const actionPayload = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
      id: threedData.id,
      artist_id: threedData.artist_id
    }, (0, _pick.default)(data, productPickedFields)), {}, {
      mockup_display_side: 'front',
      availability: data.availability || 'For Everyone',
      is_customizable: data.is_customizable || false,
      include_size_guide: data.include_size_guide || false,
      is_agree_to_terms: data.is_agree_to_terms || false,
      mockup: (0, _pick.default)(data, mockupPickedFields),
      snapshots: threedData.snapshots,
      royalty: data.royalty,
      wrap_modes: threedData.wrap_modes,
      pricing: {
        markup: Number(data.profit).toFixed(2),
        retail: Number(data.retail).toFixed(2),
        kids_markup: Number(data.kids_profit).toFixed(2),
        kids_retail: Number(data.kids_retail).toFixed(2)
      },
      is_draft: false,
      uniqueFootprint: threedData.uniqueFootprint
    }, stock_field), publish_fields), {}, {
      selectedExternalStores
    }, textureFields), {}, {
      errorCallback: data.errorCallback,
      callback: (result_data = {}, isShowMultiplePublishFailedPrompt = false) => {
        // if (locationSearchParams.store_session_id){
        //   history.push(`/shopify-store-details/${locationSearchParams.store_session_id}`)
        // } else {
        //   if(result_data.visibility === 'Public'){
        //     history.push('/artist-design?tab=Approved')
        //   } else {
        //     history.push('/artist-design?tab=Private')
        //   }
        // }
        // dispatch(HideDialog())
        // dispatch(
        //   GenerateDesignMockup({
        //     mockup_group_id: result_data.mockup.mockup_group_id,
        //     product_id: result_data.id,
        //     // loadingCallback: (loading) => setIsGenerating(loading),
        //     callback: () => {}
        //   })
        // )
        if (isShowMultiplePublishFailedPrompt) {
          setTimeout(() => {
            dispatch((0, _actions.ShowDialog)({
              uniqueEntity: 'multiple-store-publish-failed',
              data: {},
              context: _context.dialogContext.multipleStorePublishFailed
            }));
          }, 500);
        } //Redirect to admin-experience-app


        if (window.config.admin_experience_app_url) {
          // window.location.href = `${window.config.admin_experience_app_url}/admin/product/${result_data.id}`
          setDonePublish(true);
        }
      },
      shopifyCallback: (shopifyProductResult = []) => {
        setPublishedShopifyResult(shopifyProductResult);
      }
    });

    dispatch((0, _actions2.CreatePublishProduct)(actionPayload));
  };

  const handlePreAuth = (callback = () => {}) => {
    // CHECK IF USER IS LOGGED IN
    // PROMPT SIGNIN/SIGNUP DIALOG IF USER IS NOT LOGGED IN
    dispatch({
      type: 'IS_EDITOR_SAVING_CHANGES'
    });

    if (!session.id) {
      // PROMPT SIGNIN/SIGNUP DIALOG IF NO LOGGED IN USER
      dispatch((0, _actions.ShowDialog)({
        uniqueEntity: 'design_editor',
        onCloseCallback: () => {
          dispatch({
            type: 'IS_EDITOR_SAVING_CHANGES_DONE'
          });
        },
        data: {
          isShowSuccessToast: false,
          isSignupAllowed: true,
          view: 'sign-up',
          callback: callback
        },
        context: _contexts.dialogContext.signinSignupUser
      }));
      return;
    }

    callback();
  };

  const handleUploadImages = (params = {}) => {
    studioUploadApply.current = _objectSpread({}, params);
  };

  const handleLibraryImageUploadedAndApply = uploadedImage => {
    if (!uploadedImage) return;
    const _studioUploadApply$cu = studioUploadApply.current,
          handleShowPartsDialogPrompt = _studioUploadApply$cu.handleShowPartsDialogPrompt,
          handleImageElementUpload = _studioUploadApply$cu.handleImageElementUpload;
    const artistLibraryRecord = {
      name: uploadedImage.name,
      artist_id: session.id,
      status: 'Active',
      file: uploadedImage
    };
    dispatch((0, _actions2.ProcessArtistLibraryUploadApply)({
      data: [artistLibraryRecord],
      postDispatches: [(resultData = []) => {
        const success_files = resultData.map(e => e.file);
        setOpenUploader(false);

        if (!handleShowPartsDialogPrompt) {
          handleUpdateEditorElemementPhotos(success_files);
          handleImageElementUpload(success_files);
        } else {
          handleShowPartsDialogPrompt(() => {
            handleUpdateEditorElemementPhotos(success_files);
            handleImageElementUpload(success_files);
          });
        }
      }]
    }));
  };

  const handlePublishButtonClick = publishThreedData => {
    setThreedData(publishThreedData);

    if (session.enterprise_artist) {
      handleSaveChanges({
        session_data: session,
        threed_data: _objectSpread(_objectSpread({}, publishThreedData), {}, {
          design_complete: true
        }),
        product_data: productData,
        callback: () => {
          // window.location.href = `${window.config.admin_experience_app_url}/admin/product`
          setisPostMarkCompleteDialogOpen(true);
        }
      });
      return;
    }

    handleShowPublishWizardDialog(true);
  };

  const handleDoneSavingServiceWorker = () => {
    dispatch({
      type: 'IS_EDITOR_SAVING_CHANGES_DONE'
    });
  };

  const handleNavPromptSaveChanges = (data = {}) => {
    handleSaveChanges({
      session_data: session,
      threed_data: data,
      product_data: _objectSpread(_objectSpread({}, productData), {}, {
        product_name: productName
      }),
      callback: () => {
        if (dialog.isVisible && dialog.uniqueEntity === 'nav-prompt-destination-dashboard' || dialog.uniqueEntity === 'nav-prompt-destination-profile' || dialog.uniqueEntity === 'nav-prompt-destination-shop') {
          setTimeout(() => {
            if (dialog.uniqueEntity === 'nav-prompt-destination-dashboard') {
              window.addEventListener('beforeunload', function () {
                return '';
              });
              dispatch((0, _actions.DialogUpdating)());
              window.location.href = `${window.config.admin_experience_app_url}/admin/product`;
              dispatch((0, _actions.DialogUpdated)());
              dispatch((0, _actions.HideDialog)());
            }

            if (dialog.uniqueEntity === 'nav-prompt-destination-profile') {
              window.location.href = `${window.config.admin_experience_app_url}/admin/settings?tab=profile`;
            }

            if (dialog.uniqueEntity === 'nav-prompt-destination-shop') {
              window.location.href = `${window.config.admin_experience_app_url}/${generateProfileNavProps(dialog.userSession).route}`;
            }
          }, 500);
          return;
        }

        dispatch((0, _actions.DialogUpdated)());
        dispatch((0, _actions.HideDialog)());

        if (dialog.isVisible && dialog.uniqueEntity === 'nav-prompt-destination-category') {
          // history.replace('/create', { style: null, new: true })
          dispatch({
            type: 'PROCESSING_APP'
          });
          window.location.replace('/create');
          return;
        }

        if (dialog.isVisible && dialog.uniqueEntity === 'nav-prompt-destination-logout') {
          dispatch((0, _actions.LogoutUser)()); // close the queue

          socket.end(); // open the socket

          socket.open(); // remove event listener

          socket.removeAllListeners('reconnected');
          socket.isQueue = false;
          window.location.href = `${window.config.admin_experience_app_url}/login`;
        }

        navProps.onConfirm();
      }
    });
  }; // END <-- CUSTOM LOGIC HANDLERS ---------------------------------------------------------------------------------------


  return {
    page_props: props,
    // values
    values: {
      currentView,
      threedData,
      styleComponents,
      backgroundPhotos: [...backgroundPhotos, ...(0, _tools2.populateBackgrounds)()],
      elementPhotos,
      isShowAddedToCartDialog,
      cartDetails,
      cartLength,
      isStyleUpdateEnabled,
      solidColors,
      elementCategoryGroup,
      quickTipEvent,
      showRedirectDialog,
      productName,
      productData,
      selectedHistoryData,
      locationSearchParams,
      openPublishDialog,
      selectedDistributionChannels,
      isDonePublish,
      publishedShopifyResult,
      mockups,
      studioUploadApply,
      openUploader,
      isPostMarkCompleteDialogOpen,
      openVersionHistory,
      currentProductVersion,
      latestStyleData
    },
    setters: {
      setThreedData,
      setCurrentView,
      setProductName,
      setOpenUploader,
      setisPostMarkCompleteDialogOpen,
      setOpenVersionHistory
    },
    // handlers
    handlers: {
      checkMultipleAccess,
      handleCancelApprovalSubmissionDialog,
      handleCartQuantityChange,
      handleFetchStylesList,
      handleGetReadOnlyData,
      handleHeadlessSave,
      handlePreSaveFunction,
      handlePrompUpdateStyleVersion,
      handleRemoveEditorBackgroundPhotos,
      handleRemoveToCart,
      handleRemoveUploadedBranding,
      handleReviseDesign,
      handleShowAddToCart,
      handleShowBackgroundDialog,
      handleShowElementDialog,
      handleShowPublishConfirmationDialog,
      handleShowPublishDialog,
      handleShowTutorialVideos,
      handleStyleSelect,
      handleThreeDChange,
      handleToggleShowAddedToCartDialog,
      handleUnpublishDesignDialog,
      handleUpdateCart,
      handleUpdateEditorElemementPhotos,
      handleViewChange,
      handleViewTutorial,
      handleAddToCart,
      setShowRedirectDialog,
      showQuickTip,
      tabId,
      handleFetchHistory,
      handleSetSelectedHistoryData,
      handleShowSafariPrompt,
      handleSaveDetailsChanges,
      handleShowPublishWizardDialog,
      handleSelectDistributionChannel,
      handleSubmitPublish,
      handlePreAuth,
      handleWebflowSignup,
      handletSetDonePublish,
      handleSaveChanges,
      handleSaveAs,
      handleLibraryImageUploadedAndApply,
      handleUploadImages,
      handlePublishButtonClick,
      handleDoneSavingServiceWorker,
      handleNavPromptSaveChanges
    }
  };
};

exports.default = _default;