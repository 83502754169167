"use strict";

exports.__esModule = true;
exports.default = _default;

/*
  NOTE: You can use props here to initialize state
*/
function _default() {
  const _this$props = this.props,
        dialog = _this$props.dialog,
        session = _this$props.session;
  const _dialog$data = dialog.data,
        data = _dialog$data === void 0 ? {} : _dialog$data;
  const _data$threed_data = data.threed_data,
        threed_data = _data$threed_data === void 0 ? {} : _data$threed_data;

  const getCurrentTab = () => {
    if (threed_data && threed_data.style_type_name.toLowerCase().includes('denim')) {
      return 'denim';
    }

    return data.selectedMenu || data.recentElementDialogTab || 'dynamic_image';
  };

  return {
    currentTab: getCurrentTab(),
    elementPhotos: [],
    unsupportedImages: [],
    isUploading: false,
    errors: {},
    selectedPattern: {},
    stockImagesSearchValues: [],
    selectedProductCategory: {},
    selectedCategories: [],
    dynamicImagesSearchValue: '',
    selectedDynamicImage: {},
    stockImagesSearchValue: '',
    libraryUnsupportedImages: [],
    libraryImages: [],
    tempLibraryRecords: [],
    patternsSearchValue: '',
    librarySearchValue: '',
    subCategoryFilter: [],
    folders: [],
    folder_images: [],
    folderId: ''
  };
}