"use strict";

exports.__esModule = true;
exports.DenimTab = exports.DynamicImageTemplatesTab = exports.SolidColorsTabV2 = exports.SearchField = exports.TemplatesTab = exports.AddElementsTab = exports.MyLibraryTab = exports.UploadImageTab = exports.PatternsTab = exports.DynamicImageTab = exports.StockImagesSinglePartTab = exports.StockImagesSeamlessTab = exports.SolidColorsTab = void 0;

var _SolidColorsTab = _interopRequireDefault(require("./SolidColorsTab"));

exports.SolidColorsTab = _SolidColorsTab.default;

var _StockImagesSeamlessTab = _interopRequireDefault(require("./StockImagesSeamlessTab"));

exports.StockImagesSeamlessTab = _StockImagesSeamlessTab.default;

var _StockImagesSinglePartTab = _interopRequireDefault(require("./StockImagesSinglePartTab"));

exports.StockImagesSinglePartTab = _StockImagesSinglePartTab.default;

var _DynamicImageTab = _interopRequireDefault(require("./DynamicImageTab"));

exports.DynamicImageTab = _DynamicImageTab.default;

var _PatternsTab = _interopRequireDefault(require("./PatternsTab"));

exports.PatternsTab = _PatternsTab.default;

var _UploadImageTab = _interopRequireDefault(require("./UploadImageTab"));

exports.UploadImageTab = _UploadImageTab.default;

var _MyLibraryTab = _interopRequireDefault(require("./MyLibraryTab"));

exports.MyLibraryTab = _MyLibraryTab.default;

var _AddElementsTab = _interopRequireDefault(require("./AddElementsTab"));

exports.AddElementsTab = _AddElementsTab.default;

var _TemplatesTab = _interopRequireDefault(require("./TemplatesTab"));

exports.TemplatesTab = _TemplatesTab.default;

var _SearchField = _interopRequireDefault(require("./SearchField"));

exports.SearchField = _SearchField.default;

var _SolidColorsTabV = _interopRequireDefault(require("./SolidColorsTabV2"));

exports.SolidColorsTabV2 = _SolidColorsTabV.default;

var _DynamicImageTemplatesTab = _interopRequireDefault(require("./DynamicImageTemplatesTab"));

exports.DynamicImageTemplatesTab = _DynamicImageTemplatesTab.default;

var _DenimTab = _interopRequireDefault(require("./DenimTab"));

exports.DenimTab = _DenimTab.default;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }